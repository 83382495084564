<template>
  <section class="project">
    <b-container class="px-lg-0" fluid>
      <b-row no-gutters>
        <b-col sm="12" lg="6">
          <div class="img-wrap ratio-60">
            <div class="img-content">
              <b-img
                fluid
                class="pa-3"
                :src="require('@/assets/imgs/illustrations/project/001.svg')"
              ></b-img>
            </div>
          </div>
        </b-col>
        <b-col sm="12" lg="6" class="project__content">
          <section>
            <h1 class="main-heading">{{ $t("heading.start_your_project") }}</h1>
            <p
              class="main-description main-description--project "
              v-html="startDescription"
            >
              <!-- {{ startDescription }} -->
            </p>
            <Button
              class="button button--filled button--md py-3 mt-4"
              :text="$t('button.start_project')"
              @click="toggleOverlay"
            ></Button>
          </section>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Project",
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.startDescription = this.settings(
          "global_start_project_description"
        );
      },
      immediate: true
    }
  },
  data() {
    return {
      startDescription: ""
    };
  },
  methods: {
    toggleOverlay() {
      this.$store.dispatch("SetGlobalOverlay", {
        type: "service",
        isOpen: true
      });
    }
  }
};
</script>
